import React, { FunctionComponent as FC, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';
import './index.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid as Grid } from 'react-window';
import { Select, Spin, Button, Tooltip, Breadcrumb, Empty, Pagination } from 'antd';
import _, { isEmpty } from 'lodash';

import { ResearcherStatisticsTypes } from '../../../../../types/data';
import { GlobalStyle } from '../../../../../types/style.d';
import ResearcherCard from '../../../common/Visualization/ResearcherCard';
import ResearcherDetails from '../ResearcherDetails';

import "./index.scss";


interface ResearcherViewProps {
  viewName: string | null;
}

const ResearcherView: FC<ResearcherViewProps> = (props) => {

  const {viewName} = props

  const isInitialMount = useRef(true);
  
  // store
  const dispatch = useDispatch()
  const {
    // TODO: 添加获取researcher card list data的action
    // getPaperInspectionData,
    // getPatentInspectionData,
    getResearcherCardListData, 
    setSelectedResearcherID, 
    setSelectedAuthorID,
    setResearcherRankByField,
    setResearcherRankOrder,
  } = bindActionCreators(actionCreators, dispatch)

  const researcherRankByField: string = useSelector((state: State) => state.dataReducer?.researcherRankByField) as string
  const researcherRankOrder: string = useSelector((state: State) => state.dataReducer?.researcherRankOrder) as string
  const searchFacultyKeyword: string = useSelector((state: State) => state.dataReducer?.searchFacultyKeyword) as string
  const selectedResearcherIdList: string[] = useSelector((state: State) => state.dataReducer?.selectedResearcherIdList) as string[]
  const selectedAuthorId: string = useSelector((state: State) => state.dataReducer?.selectedAuthorId) as string
  const selectedResearcherId: ResearcherStatisticsTypes.ResearcherStatisticsUnit = useSelector((state: State) => state.dataReducer?.selectedResearcherId) as ResearcherStatisticsTypes.ResearcherStatisticsUnit

  const researcherStatisticsList: ResearcherStatisticsTypes.ResearcherStatisticsUnit[] = useSelector((state: State) => state.dataReducer?.researcherStatisticsData) as ResearcherStatisticsTypes.ResearcherStatisticsUnit[]
  const researcherCardSize: number = useSelector((state: State) => state.dataReducer?.researcherCardSize) as number

  const untapFilterStatus: string = useSelector((state: State) => state.uiReducer?.researcherCardListFilter.untapFilter) as string

  const [isResearcherViewLoading, setIsResearcherViewLoading] = useState(false);
  const [sortedResearcherListData, setSortedResearcherListData] = useState<ResearcherStatisticsTypes.ResearcherStatisticsUnit[]>([]);
  const [selectedResearcher, setSelectedResearcher] = useState<ResearcherStatisticsTypes.ResearcherStatisticsUnit | null>(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [currentViewName, setCurrentViewName] = useState(viewName);
  const [cardListSize, setCardListSize] = useState(researcherStatisticsList.length);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if(selectedResearcherId){
      setSelectedResearcher(selectedResearcherId);
    }
  },[selectedResearcherId])

  useEffect(() => {
    if(untapFilterStatus !== 'all'){
      console.log("useEffect 0");
      setResearcherRankByField('numHighScoreLeadAuthorPapers');
      setResearcherRankOrder('descending');
    }
  }, [untapFilterStatus])


  // console.log('researcherStatisticsList:', researcherStatisticsList);
  useEffect(() => {
      // when not coming from IdeaFinder but initial mounting, don't 
      // run rest of code due to redundancy (useEffect 3)
      if (isInitialMount.current && currentViewName!='Idea-Finder'){
        console.log("firstMount")
        isInitialMount.current = false;
      } 

      // when author is selected from PF/RV/RD or IF 
      // e.g. select author(from IF) -> PF/RV/RD -> click breadcrumb "all"
      else if (!isInitialMount.current || currentViewName=='Idea-Finder'){
        isInitialMount.current = false; // if from Idea-Finder
        //uncommnent for author click from paper card
        // console.log("useEffect 1" );
        // const updateResearcherCardListData = async (selectedId:any) => {
        //   try {
        //     setIsResearcherViewLoading(true)
        //     setSelectedResearcherID(selectedId);
        //     setCurrentPage(1);
        //     if(Array.isArray(selectedId)){
        //       var params = {
        //         researcherIdList: selectedId,
        //         untapFilter: untapFilterStatus,
        //         rankingMetric: researcherRankByField,
        //         rankingOrder: researcherRankOrder,
        //         pageNumber: 1,
        //       }
        //       await getResearcherCardListData(params);
        //     } else{
        //       var params = {
        //         researcherIdList: [selectedId],
        //         untapFilter: untapFilterStatus,
        //         rankingMetric: researcherRankByField,
        //         rankingOrder: researcherRankOrder,
        //         pageNumber: 1,
        //       }
        //       await getResearcherCardListData(params)
        //     }
            
        //     //console.log('getResearcherCardListData:', selectedAuthor);           
        //   } catch (error) {
        //     console.error(error);
        //     setIsResearcherViewLoading(false) 
        //   } finally {
        //     // console.log('Researcher View update finished');
        //     setIsResearcherViewLoading(false)            
        //   }
        // }
        // if(selectedAuthorId!=''){
        //   console.log("selectedAuthor: ", selectedAuthorId);
          
        //   setSelectedResearcherID(selectedAuthorId);
        //   updateResearcherCardListData(selectedAuthorId);
        // } else{
        //   //console.log("back to unselected author")
        //   updateResearcherCardListData(selectedResearcherIdList);
        // }
      }
  }, [selectedAuthorId]); 


  // To handle cases for when not coming from IdeaFinder page
  useEffect(() => {
    console.log("viewName: ", currentViewName)
    //console.log("initialMount: ", isInitialMount, isInitialMount.current)
    if (currentViewName!="Idea-Finder"){
      console.log("useEffect 3" );
      if(_.isEmpty(selectedResearcherIdList)){
        console.log("EMPTY")
        setIsEmpty(true);
      } else{
        setIsEmpty(false);
      }
      if(!_.isEmpty(selectedResearcherIdList)){

        const updateResearcherCardListData = async () => {
          try {
            // console.log("==> in Researcher View getResearcherCardListData")
            setIsResearcherViewLoading(true)
            setCurrentPage(1);
            // TODO: 待补充
            // After detecting changes in selectedResearcherIdList, call the backend API to get ResearcherCardListData
            var params = {
              researcherIdList: selectedResearcherIdList,
              untapFilter: untapFilterStatus,
              rankingMetric: researcherRankByField,
              rankingOrder: researcherRankOrder,
              pageNumber: 1,
            }
            await getResearcherCardListData(params);
            //console.log('getResearcherCardListData:', selectedResearcherIdList);           
          } catch (error) {
            console.error(error);
            setIsResearcherViewLoading(false) 
          } finally {
            //console.log('Researcher View update finished');
            setIsResearcherViewLoading(false)            
          }
        }
        updateResearcherCardListData();
      }
    }
    else{
      //when coming from IF but query values changed
      setCurrentViewName("People-Finder");
      //viewName = "People-Finder"; //doesn't change the value
      //change viewname back to People-Finder? Need this behavior line 79
    }
  }, [selectedResearcherIdList, researcherRankByField, researcherRankOrder]) //changes from QueryView
  
  // 监测researcherRankByField, researcherRankOrder的改变进行重新排序
  useEffect(() => {
    console.log("useEffect 4" );
    if (selectedAuthorId=='' && researcherStatisticsList.length > 0){
      let sortedResearcherList: ResearcherStatisticsTypes.ResearcherStatisticsUnit[] = researcherStatisticsList.slice()
    
      // 过滤关键词
      if (searchFacultyKeyword) {
        const keyword = searchFacultyKeyword.toLowerCase();
        let sortedResearcherListTemp = sortedResearcherList.filter(researcher =>
          researcher.demographicInfo.fullName.toLowerCase().includes(keyword)
        );
        if (_.isEmpty(sortedResearcherListTemp)){
          setIsEmpty(true);
        } else{
          setIsEmpty(false);
          sortedResearcherList = sortedResearcherListTemp;
        }
      }
  
      // 排序
      // if(researcherRankByField === 'numInventions'){
      //   sortedResearcherList = sortedResearcherList.sort(function(a, b){
      //     if(researcherRankOrder === 'ascending'){
      //       return (b.scisciIndex.numInventions < a.scisciIndex.numInventions) ? 1 : (b.scisciIndex.numInventions > a.scisciIndex.numInventions)? -1 : 0;
      //     }
      //     else{
      //       // descending
      //       return (b.scisciIndex.numInventions > a.scisciIndex.numInventions) ? 1 : (b.scisciIndex.numInventions < a.scisciIndex.numInventions)? -1 : 0;
      //     }
      //   })
      // }
      // else if(researcherRankByField === '# patents'){
      //   sortedResearcherList = sortedResearcherList.sort(function(a, b){
      //     if(researcherRankOrder === 'ascending'){
      //       return (b.scisciIndex.numPatents < a.scisciIndex.numPatents) ? 1 : (b.scisciIndex.numPatents > a.scisciIndex.numPatents)? -1 : 0;
      //     }
      //     else{
      //       // descending
      //       return (b.scisciIndex.numPatents > a.scisciIndex.numPatents) ? 1 : (b.scisciIndex.numPatents < a.scisciIndex.numPatents)? -1 : 0;
      //     }
      //   })
      // }
      // else if(researcherRankByField === 'numPapersCitedByPatents'){
      //   sortedResearcherList = sortedResearcherList.sort(function(a, b){
      //     if(researcherRankOrder === 'ascending'){
      //       return (b.scisciIndex.numPapersCitedByPatents < a.scisciIndex.numPapersCitedByPatents) ? 1 : (b.scisciIndex.numPapersCitedByPatents > a.scisciIndex.numPapersCitedByPatents)? -1 : 0;
      //     }
      //     else{
      //       // descending
      //       return (b.scisciIndex.numPapersCitedByPatents > a.scisciIndex.numPapersCitedByPatents) ? 1 : (b.scisciIndex.numPapersCitedByPatents < a.scisciIndex.numPapersCitedByPatents)? -1 : 0;
      //     }
      //   })
      // }
      // else if(researcherRankByField === 'numHighScoreLeadAuthorPapers'){
      //   sortedResearcherList = sortedResearcherList.sort(function(a, b){
      //     if(researcherRankOrder === 'ascending'){
      //       return (b.scisciIndex.numHighScoreLeadRecentPapers < a.scisciIndex.numHighScoreLeadRecentPapers) ? 1 : (b.scisciIndex.numHighScoreLeadRecentPapers > a.scisciIndex.numHighScoreLeadRecentPapers)? -1 : 0;
      //     }
      //     else{
      //       // descending
      //       return (b.scisciIndex.numHighScoreLeadRecentPapers > a.scisciIndex.numHighScoreLeadRecentPapers) ? 1 : (b.scisciIndex.numHighScoreLeadRecentPapers < a.scisciIndex.numHighScoreLeadRecentPapers)? -1 : 0;
      //     }
      //   })
      // }
      // else if(researcherRankByField === 'pIndex'){
      //   sortedResearcherList = sortedResearcherList.sort(function(a, b){
      //     if(researcherRankOrder === 'ascending'){
      //       return (b.scisciIndex.pIndex < a.scisciIndex.pIndex) ? 1 : (b.scisciIndex.pIndex > a.scisciIndex.pIndex)? -1 : 0;
      //     }
      //     else{
      //       // descending
      //       return (b.scisciIndex.pIndex > a.scisciIndex.pIndex) ? 1 : (b.scisciIndex.pIndex < a.scisciIndex.pIndex)? -1 : 0;
      //     }
      //   })
      // }
      // else{
      //   //researcherRankByField === '# inventions'
      //   sortedResearcherList = sortedResearcherList.sort(function(a, b){
      //     if(researcherRankOrder === 'ascending'){
      //       return (b.scisciIndex.numInventions < a.scisciIndex.numInventions) ? 1 : (b.scisciIndex.numInventions > a.scisciIndex.numInventions)? -1 : 0;
      //     }
      //     else{
      //       // descending
      //       return (b.scisciIndex.numInventions > a.scisciIndex.numInventions) ? 1 : (b.scisciIndex.numInventions < a.scisciIndex.numInventions)? -1 : 0;
      //     }
      //   })
      // }
      setSortedResearcherListData(sortedResearcherList)
    }
  }, [searchFacultyKeyword])

  useEffect(() => {
    console.log("useEffect 5" );
    if (selectedAuthorId!='' && researcherStatisticsList.length > 0 ) {
      setSelectedResearcher(researcherStatisticsList[0])
      //console.log("Selected Researcher after selecting Author: ", selectedResearcher);
    }else{
      let sortedResearcherList: ResearcherStatisticsTypes.ResearcherStatisticsUnit[] = researcherStatisticsList.slice()
      setSortedResearcherListData(sortedResearcherList)
      setCardListSize(researcherCardSize)
    }
  }, [researcherStatisticsList, researcherCardSize])

  useEffect(() => {
    console.log("useEffect 6" );
    if (selectedAuthorId == '' ) {
      if(!_.isEmpty(selectedResearcherIdList)){

        const updateResearcherCardListData = async () => {
          try {
            // console.log("==> in Researcher View getResearcherCardListData")
            setIsResearcherViewLoading(true)
            // TODO: 待补充
            // After detecting changes in selectedResearcherIdList, call the backend API to get ResearcherCardListData
            var params = {
              researcherIdList: selectedResearcherIdList,
              untapFilter: untapFilterStatus,
              rankingMetric: researcherRankByField,
              rankingOrder: researcherRankOrder,
              pageNumber: currentPage,
            }
            await getResearcherCardListData(params);
            //console.log('getResearcherCardListData:', selectedResearcherIdList);           
          } catch (error) {
            console.error(error);
            setIsResearcherViewLoading(false) 
          } finally {
            //console.log('Researcher View update finished');
            setIsResearcherViewLoading(false)            
          }
        }
        updateResearcherCardListData();
      }
    }
    
  }, [currentPage])


  // console.log('sortedResearcherListData:', sortedResearcherListData);
  const onPageChange = (page: any) => {
    setCurrentPage(page);
  }

  const handleCardClick = (researcher: ResearcherStatisticsTypes.ResearcherStatisticsUnit) => {
    console.log('click Researcher: ', researcher);
    setSelectedResearcher(researcher);
    setSelectedResearcherID(researcher); // update for RD to fetch data
  };

  const handleBreadcrumbClick = (event: React.MouseEvent) => {
    console.log("handleBreadcrumbClick" );
    event.preventDefault();
    setSelectedResearcher(null);
    setSelectedResearcherID(null);
    setSelectedAuthorID("");
  };

  const breadcrumbItems = [
    { title: 'Researchers' },
    { title: <a href="">All</a>, onClick: handleBreadcrumbClick },
    ...(selectedResearcher ? [{ title: <a href="">{selectedResearcher.demographicInfo.fullName}</a> }] : [])
  ];

  const cardWidth = 340; // 固定卡片宽度
  const cardHeight = 270; // 固定卡片高度
  const gap = 20; // 卡片之间的间隔



  const marks = {
    2011: '2011',
    2023: '2023', 
    80: '80',
    100: '100'
  };


  // set paper card width
  const researcherCardParentDivRef = React.useRef<HTMLDivElement>(null);
  const [researcherCardParentDivWidth, setResearcherCardParentDivWidth] = React.useState(0);
  React.useEffect(() => {
    if (researcherCardParentDivRef.current) {
      setResearcherCardParentDivWidth(researcherCardParentDivRef.current.offsetWidth);
    }
  }, []);

  
  const researcherCardMargin = 16
  // const paperCardWidth_min = 400 
  const numResearcherCardsPerRowDict: { [windowWidth: number]: number} = {
    800: 1, 
    1080: 2, 
    // 1240: 3, 
    1920: 4, 
    2560: 5, 
  }
  let numResearcherCardsPerRow = 1; // Default value
  for (const width in numResearcherCardsPerRowDict) {
    if (researcherCardParentDivWidth >= parseInt(width)) {
      numResearcherCardsPerRow = numResearcherCardsPerRowDict[width];
    }
  }
  //console.log('numResearcherCardsPerRow:', numResearcherCardsPerRow)
  const researcherCardWidth = (researcherCardParentDivWidth - numResearcherCardsPerRow * researcherCardMargin * 2 - 2) / numResearcherCardsPerRow;

  const researcherCardList: JSX.Element[] = sortedResearcherListData.map((researcherCard, index) => {
    // const numResearcherCardsPerRow = Math.floor(paperCardParentDivWidth / (paperCardWidth_min + 2 * paperCardMargin));
    return (
      <div style={{ margin: 0, padding: 0 }} onClick={() => handleCardClick(sortedResearcherListData[index])}>
        <ResearcherCard
          key={sortedResearcherListData[index].empidHash}
          viewParam={{ width: researcherCardWidth, height: cardHeight, margin: researcherCardMargin, padding: 4 }}
          ResearcherStatisticsData={sortedResearcherListData[index]}
          boxShadowStyle="0 4px 10px 0 rgba(0, 0, 0, 0.2)"
          divOutlineColor={'#e9e7f2'}
        />
      </div>
    )
  })


  return (
    <div className='researcher-view-div'>
      <Spin tip="Loading" spinning={isResearcherViewLoading} size='large' className='researcher-view-custom-spin'>
      {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
              Researchers
            </Breadcrumb.Item>
          <Breadcrumb.Item onClick={handleBreadcrumbClick}>
              All
          </Breadcrumb.Item>
           {selectedResearcher && <Breadcrumb.Item>{selectedResearcher.demographicInfo.fullName}</Breadcrumb.Item>}
        </Breadcrumb> */}
        {/* <Breadcrumb style={{ marginTop: "16px", marginBottom: "16px", marginLeft: "30px", fontSize: "12px" }} items={breadcrumbItems} /> */}
        <Breadcrumb className="breadcrumb" items={breadcrumbItems} />

        {isEmpty ? (
          <div className='is-empty'>
            <Empty description='No Researchers' />
          </div>
        ) : (
          
          <div className='researcher-card-group-div' ref={researcherCardParentDivRef} 
              //  style={{ width: '100%', height: 'calc(100vh - 196px)'}}
          >
            { (selectedResearcher || selectedResearcherId) && selectedResearcher && (<ResearcherDetails researcher={selectedResearcher} />) }
            { (!selectedResearcher) && researcherCardList }
          </div>
          
        )}


      {/* <div className='researcher-card-group-div' style={{ width: '100%', height: 'calc(100vh - 196px)'}}>
        <AutoSizer>
           {({ height, width }: { height: number; width: number })  => {
             if (selectedResearcher) {
              console.log("if selectedResearcher")
              //console.log("Selected Researcher for RD: ",selectedResearcher)
              return (
                <ResearcherDetails researcher={selectedResearcher} />
              );
            } else {
              const columnCount = Math.floor(width / (cardWidth + gap));
              const totalCardWidth = columnCount *  (cardWidth + gap);
              const remainingSpace = width - totalCardWidth;
              const adjustedGap = remainingSpace / (columnCount + 1);

              // console.log('columnCount:', columnCount);
              //console.log('sortedResearcherListData: ',sortedResearcherListData)
              console.log("else selectedResearcher")
              return (
                <Grid
                  columnCount={columnCount}
                  columnWidth={cardWidth + gap}
                  height={height}
                  rowCount={Math.ceil(sortedResearcherListData.length / columnCount)}
                  rowHeight={cardHeight + gap}
                  width={width}
                >
                  {({ columnIndex, rowIndex, style }) => {
                    const index = rowIndex * columnCount + columnIndex;
                    if (index >= sortedResearcherListData.length) return null;
                    return (
                      <div
                        style={{
                          ...style,
                          left: (style.left as number) + (columnIndex+1) * adjustedGap,
                          top: (style.top as number) + gap / 2,
                        }}
                        onClick={() => handleCardClick(sortedResearcherListData[index])}
                      >
                        <ResearcherCard
                          key={sortedResearcherListData[index].empidHash}
                          viewParam={{ width: cardWidth, height: cardHeight, margin: 20, padding: 4 }}
                          ResearcherStatisticsData={sortedResearcherListData[index]}
                          boxShadowStyle="0 4px 10px 0 rgba(0, 0, 0, 0.2)"
                          divOutlineColor={'#e9e7f2'}
                        />
                      </div>
                    );
                  }}
                </Grid>
              );
            }
          }}
        </AutoSizer>
      </div> */}
      </Spin>
      {!selectedResearcher && (
        <div className='researcher-view-pagination'>
            <Pagination current={currentPage} defaultCurrent={1} total={cardListSize} pageSize={100} showSizeChanger={false} onChange={onPageChange}/>
        </div>
      )}

    </div>
  );
}

export default ResearcherView