import React, { FunctionComponent as FC } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import { State } from '../../../../state';
import './index.scss';

import {
  SearchOutlined,
  UserOutlined,
  CodepenOutlined,
  DeploymentUnitOutlined,
  BookOutlined
} from '@ant-design/icons';
import { Slider, Switch, Select, Button, Input, ConfigProvider } from 'antd';
import {CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons'
import type { SliderMarks } from 'antd/es/slider';

import { PaperFieldLevel } from '../../../../types/data.global';
import { MatrixTypes2, InteractionTypes } from '../../../../types/data';


interface PagePanelProps {
  viewTitle: string;
  // width: number;
  // height: number;
  panelHeaderHeight: number;
  // x?: number;
  // y?: number;
  children: React.ReactNode; // 👈️ added type for children
}

const PagePanel: FC<PagePanelProps> = (props) => {

  const {
    viewTitle,
    // width,
    // height,
    panelHeaderHeight,
  } = props

  const { Search } = Input;

  // store
  const dispatch = useDispatch()
  const {
    setResearcherRankByField,
    setResearcherRankOrder,
    setSearchFacultyKeyword,
    setPaperRankByField,
    setPaperRankOrder,
    setSearchPaperKeyword,
  } = bindActionCreators(actionCreators, dispatch)

  const researcherRankByField: string = useSelector((state: State) => state.dataReducer?.researcherRankByField) as string

  const handleRankByFieldChange = (value: string) => {
    console.log('handleRankByFieldChange', value)
    setResearcherRankByField(value)
  }

  const handleRankOrderChange = (value: string) => {
    console.log('handleRankOrderChange', value)
    setResearcherRankOrder(value)
  }

  const handleSearchFacultyKeywordChange = (value: string) => {
    console.log('handleSearchFacultyKeywordChange', value)
    setSearchFacultyKeyword(value)
  }

  const handlePaperRankByFieldChange = (value: string) => {
    console.log('handlePaperRankByFieldChange', value)
    setPaperRankByField(value)
  }

  const handlePaperRankOrderChange = (value: string) => {
    console.log('handlePaperRankOrderChange', value)
    setPaperRankOrder(value)
  }

  const handleSearchPaperKeywordChange = (value: string) => {
    console.log('handleSearchPaperKeywordChange', value)
    setSearchPaperKeyword(value)
  }

  const handleResearcherRankOrder = (value:boolean) => {
    if (value) {
      setResearcherRankOrder('descending')
    } else {
      setResearcherRankOrder('ascending')
    }
  }

  const handlePaperRankOrder = (value:boolean) => {
    if (value) {
      setPaperRankOrder('descending')
    } else {
      setPaperRankOrder('ascending')
    }
  }

  // 2. jsx element
  // 2-1. icon
  let iconElement: JSX.Element = <></> // <PieChartOutlined />
  // switch(viewTitle) {
  //   case 'Query View':
  //     iconElement = <SearchOutlined />
  //     break;
  //   case 'Researcher View':
  //     iconElement = <UserOutlined />
  //     break;
  //   default:
  //     break;
  // }
  // 2-2. slider & switch
  let paperTimeSlider: JSX.Element = <></>
  let patentTimeSlider: JSX.Element = <></>
  let predictionScoreSlider: JSX.Element = <></>
  let flowSlider: JSX.Element = <></>
  let sunburstSlider: JSX.Element = <></>

  let researcherRankByFieldElement: JSX.Element = <></>
  let researcherRankOrderElement: JSX.Element = <></>
  let researcherRankOrder: JSX.Element = <></>
  let searchFacultyKeywordElement: JSX.Element = <></>

  let paperRankByFieldElement: JSX.Element = <></>
  let paperRankOrderElement: JSX.Element = <></>
  let searchPaperKeywordElement: JSX.Element = <></>

  searchFacultyKeywordElement = (
    <Search placeholder="input faculty name" allowClear onSearch={handleSearchFacultyKeywordChange} style={{ width: "100%" }} />
  )

  searchPaperKeywordElement = (
    <Search placeholder="input title keyword" allowClear onSearch={handleSearchPaperKeywordChange} style={{ width: "100%" }} />
  )

  researcherRankByFieldElement = (
    <Select
      className='researcherRankField'
      style={{ width: "100%" }}
      placeholder="numInventions_2001_2023"
      onChange={handleRankByFieldChange}
      options={[
        { value: 'numHighScoreLeadAuthorPapers', label: 'Num High Score Lead Author Paper' },
        { value: 'numPapersSince2001CitedByCompanyPatents', label: 'Num Papers Since 2001 Cited By Company Patents' },
        { value: 'numInventions_2001_2023', label: 'Num Inventions 2001 - 2023' },
        // { value: '# papers', label: '# papers' },
        // { value: '# papers cited by patents', label: '# papers cited by patents' },
        //{ value: 'pIndex', label: 'avgPatentabilityPerPaper' },
      ]}
      value = {researcherRankByField}
      defaultValue={'numHighScoreLeadAuthorPapers'}
    />
  )

  researcherRankOrderElement = (
    <Select
      style={{ width: "100%", marginLeft:"0px"}}
      placeholder="Desc"
      onChange={handleRankOrderChange}
      options={[
        { value: 'ascending', label: 'ascending' },
        { value: 'descending', label: 'descending' },
      ]}
      defaultValue={'descending'}
    />
  )

  
  paperRankByFieldElement = (
    <Select
      style={{ width: "100%" }}
      placeholder="predictionScore"
      onChange={handlePaperRankByFieldChange}
      options={[
        { value: 'predictionScore', label: 'Prediction Score' },
        { value: 'patentCitationsCall', label: 'Patent Citations Call' },
      ]}
      defaultValue={'predictionScore'}
    />
  )

  paperRankOrderElement = (
    <Select
      style={{ width: "100%", marginLeft:"0px"}}
      placeholder="Desc"
      onChange={handlePaperRankOrderChange}
      options={[
        { value: 'ascending', label: 'ascending' },
        { value: 'descending', label: 'descending' },
      ]}
    />
  )

  return (
    <div className='page-panel'
          style={{
            backgroundColor: (viewTitle==='')?'#E4E0EE':'#F1F1F1', 
            // backgroundColor: (viewTitle==='')?'#E4E0EE':'#FFFFFF', F1F1F1
            // borderRadius: "8px",
          }}
    >
       <ConfigProvider
            theme={{
              token:{
                colorPrimary: '#B6ACD1',
                colorText: '#482476'
              },
              components: {
                Form: {
                  labelFontSize: 16,
                  itemMarginBottom: 6,
                },
                Select: {
                  selectorBg: "#FFFFFF",
                },
                Switch: {
                  handleBg: "#9380B6",
                  trackMinWidthSM: 50
                }
              }
            }}
          >
      <div className='panel-header' 
            style={{
              borderBottom: (viewTitle==='')?'none':'1px solid #B6ACD1'}}
      >
        <div className='panel-header-content' style={{height: viewTitle===''?10:60}}>
        {/* <div className='panel-header' style={{height: panelHeaderHeight}}> */}
          <div className='left-align'>
            <span className='panel-icon'>{iconElement}</span>
            {/* <span className="panel-title">{viewTitle}</span> */}
            <span className="panel-title">{}</span>
          </div>
          
          {(viewTitle === 'Researcher View') &&
            <div className='right-align' style={{width: "100%"}}>
              {/* <div className='select-div' style={{width: "50%", marginRight: "15px"}}>
                <div className='select-label' style={{width: "115px"}}>Search Faculty: </div>
                <div style={{width: "65%"}}>{searchFacultyKeywordElement}</div>
              </div> */}
              <div className='select-div' style={{width: "425px", marginRight: "15px"}}>
                <div className='select-label' style={{width: "65px"}}>Rank By: </div>
                <div style={{width: "100%"}}>{researcherRankByFieldElement}</div>
              </div>
              <div className='select-div' style={{width: "20%", marginRight: "15px"}}>
                <div className='select-label' style={{width: "85px"}}>Rank Order: </div>
                <div className='select-button' style={{width: "20%", display: 'flex', flexDirection: 'column'}}>
                  <Switch 
                    checkedChildren="desc" 
                    unCheckedChildren="asc" 
                    defaultChecked 
                    onChange={handleResearcherRankOrder}
                    size="small"
                  />
                </div>
              </div>
            </div>
          }
          {(viewTitle === 'Paper View') &&
            <div className='right-align' style={{width: "100%"}}>
              {/* <div className='select-div' style={{width: "50%", marginRight: "15px"}}>
                <div className='select-label' style={{width: "115px"}}>Search Paper: </div>
                <div style={{width: "65%"}}>{searchPaperKeywordElement}</div>
              </div> */}
              <div className='select-div' style={{width: "230px", marginRight: "15px"}}>
                <div className='select-label' style={{width: "65px"}}>Rank By: </div>
                <div style={{width: "75%"}}>{paperRankByFieldElement}</div>
              </div>
              <div className='select-div' style={{width: "20%", marginRight: "15px"}}>
                <div className='select-label' style={{width: "85px"}}>Rank Order: </div>
                {/* <div style={{width: "65%"}}>{paperRankOrderElement}</div> */}
                <div className='select-button' style={{width: "20%", display: 'flex', flexDirection: 'column'}}>
                  <Switch 
                    checkedChildren="desc" 
                    unCheckedChildren="asc" 
                    defaultChecked 
                    onChange={handlePaperRankOrder}
                    size="small"
                  />
                </div>
              </div>
            </div>
          }
          {(viewTitle === 'Innovation View') &&
            <div className='right-align' style={{width: 880}}>
              <div className='slider-div' style={{width: 160}}>
                <div className='slider-label' style={{width: 80}}>Flow Filter: </div>
                <div style={{width: 80}}>{flowSlider}</div>
              </div>
              {/* <div className='slider-div'>
                <div className='slider-label' style={{width: 120}}>Only Prediction: </div>
                <div style={{width: 16}}><Switch size="small" onChange={onChangeShowingPrediction} /></div>
              </div> */}
              <div className='slider-div' style={{width: 180}}>
                <div className='slider-label' style={{width: 90}}>Paper Year: </div>
                <div style={{width: 80}}>{paperTimeSlider}</div>
              </div>
              <div className='slider-div' style={{width: 180}}>
                <div className='slider-label' style={{width: 90}}>Patent Year: </div>
                <div style={{width: 80}}>{patentTimeSlider}</div>
              </div>
              <div className='slider-div' style={{width: 300}}>
                <div className='slider-label' style={{width: 160}}>Patentability Prediction: </div>
                <div style={{width: 110}}>{predictionScoreSlider}</div>
              </div>
            </div>
          }
          {(viewTitle === 'Technology Inspection') &&
            <div style={{width: 230}}>
              <div className="topKSlider">
                {/* <div style={{width: "45px", float: "left", marginLeft: "15px", marginRight: "10px", marginTop: "4px"}}>top k assignees:</div> */}
                {sunburstSlider}
              </div>
            </div>
          }
        </div>

        {/* <div className='split-line'>
          {viewTitle === ''? null:
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 997 2" fill="none">
            <path d="M0.5 1L996.5 1" stroke="#B6ACD1"/>
          </svg>
          }
        </div> */}
      </div>
 
      <div className='panel-body'>{props.children}</div>
      
      </ConfigProvider>
      
    </div>
  )
}

export default PagePanel; 