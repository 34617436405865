import React, {
  FunctionComponent as FC,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from 'react-redux'; // To call Action Creators
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import { State } from '../../../../../state';

import SubTitle from "../../../common/SubTitle";
import './index.scss';
import { Spin, Slider, Switch, Form, ConfigProvider, Select, Input, Button } from "antd";
import {
  SearchOutlined, 
} from '@ant-design/icons';

import { GlobalStyle } from '../../../../../types/style.d'; 
import Scatterplot from "../../../common/Visualization/Scatterplot";
import { getScatterplotData } from "../../../../../state/action-creators";
import { select } from "d3-selection";
import { on } from "events";

interface QueryViewProps {

}

const QueryView: FC<QueryViewProps> = (props) => {

  const {
  } = props

   // store
   const dispatch = useDispatch()
   const {
    getSelectedPaperIDList,
    setSearchPaperKeyword
   } = bindActionCreators(actionCreators, dispatch)

  const selectedResearcherIdList: string[] = useSelector((state: State) => state.dataReducer?.selectedResearcherIdList) as string[]

  const [popularFilterValue, setPopularFilterValue] = useState<string>('--');
  const [publicationYear, setPublicationYear] = useState<number[]>([2019, 2023])
  const [publicationMonth, setPublicationMonth] = useState<number[]>([1, 12])
  const [keyword, setKeyword] = useState<string>('');

  const { Search } = Input;
  

  const monthMarks: { [key: number]: string } = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const yearMarkLabels = {
    2000: '2000',
    2023: '2023', 
  };

  const monthMarkLabels = {
    1: 'Jan',
    12: 'Dec',
  };
  const formatter = (value: number | undefined): React.ReactNode => {
    if (value === undefined) return '';
    return monthMarks[value];
  };

  const updateSelectedResearcherList = async (params: any) => {
    try {
      console.log('Query View updateAllViews params: ', params);
      getSelectedPaperIDList(params);
      
      // const result = await getSelectedResearcherIDList(params);
      // console.log('Query View updateAllViews result: ', result);
      // TODO：检查此处的更新周期，getSelectedResearcherIDList处刚刚更新了selectedResearcherIdList后此处能否获到更新后的getSelectedResearcherIDList
      // const scatterplotResult = await getResearcherScatterplotData(selectedResearcherIdList);
      // console.log('Query View updateAllViews result: ', scatterplotResult);

    } catch (error) {
      console.error(error);
    } finally {
      // console.log('Query View updateAllViews finished');
    }
  }

  const handlePopularFilterChange = (value: string) => {
    // console.log("QueryView => popular filter value: ", value)
    setPopularFilterValue(value)
  }

  const onPublicationYearChangeComplete = (value: number | number[]) => {
    // console.log('onPublicationYearChangeComplete: ', value);
    setPublicationYear(value as number[]);
    var params = {
      popularFilter: popularFilterValue,
      publicationYear: value,
      publicationMonth: publicationMonth,
      keyword: keyword,
    }
    updateSelectedResearcherList(params);
  };

  const onPublicationMonthChangeComplete = (value: number | number[]) => {
    console.log('onPublicationMonthChangeComplete: ', value);
    setPublicationMonth(value as number[]);
    var params = {
      popularFilter: popularFilterValue,
      publicationYear: publicationYear,
      publicationMonth: value,
      keyword: keyword,
    }
    updateSelectedResearcherList(params);
  };

  const handleInputChange = (value: string) => {
    // console.log('handleKeywordChange', value)
    setKeyword(value)
    var params = {
      popularFilter: popularFilterValue,
      publicationYear: publicationYear,
      publicationMonth: publicationMonth,
      keyword: value,
    }
    updateSelectedResearcherList(params);
  }
  
  // const handleSearchKeywordChange = (value: string) => {
  //   console.log('handleFacultyKeywordChange', value)
  //   setKeyword(value)
  // }

  const handleConfirmButton = () => {
    // console.log('Confirm button clicked');
    var params = {
      popularFilter: popularFilterValue,
      publicationYear: publicationYear,
      publicationMonth: publicationMonth,
      keyword: keyword,
    }
    // console.log('Query View updateAllViews params: ', params);
    const updateSelectedResearcherList = async () => {
      try {
        // console.log('Query View updateAllViews params: ', params);
        getSelectedPaperIDList(params);
        
        // const result = await getSelectedResearcherIDList(params);
        // console.log('Query View updateAllViews result: ', result);
        // TODO：检查此处的更新周期，getSelectedResearcherIDList处刚刚更新了selectedResearcherIdList后此处能否获到更新后的getSelectedResearcherIDList
        // const scatterplotResult = await getResearcherScatterplotData(selectedResearcherIdList);
        // console.log('Query View updateAllViews result: ', scatterplotResult);

      } catch (error) {
        console.error(error);
      } finally {
        // console.log('Query View updateAllViews finished');
      }
    }
    updateSelectedResearcherList()
  }

  const handleSearchPaperKeywordChange = (value: string) => {
    console.log('handleSearchPaperKeywordChange', value)
    setSearchPaperKeyword(value)
  }

  let searchPaperKeywordElement: JSX.Element = <></>

  searchPaperKeywordElement = (
    <Search placeholder="input title keyword" size="middle" allowClear onSearch={handleInputChange} style={{ width: "100%" }} />
  )

  return (
    <div className='query-view-div'>
      <ConfigProvider
            theme={{
              token:{
                colorPrimary: '#404040',
                borderRadius: 14,
                colorText: '#482476',
                // fontSize: 12,
              },
              components: {
                Form: {
                  labelFontSize: 16,
                  itemMarginBottom: 6,
                },
                Slider: {
                  // dotActiveBorderColor: '#836EAA',
                  // dotBorderColor: '#A495C3',
                  handleColor: '#A495C3',
                  handleActiveColor: '#A495C3',
                  handleColorDisabled: '#A495C3',
                  handleSize: 6,
                  handleSizeHover: 8,
                  railBg:  '#F7F7F7',
                  railSize: 10,
                  trackBg: '#A495C3',
                  trackHoverBg: '#836EAA',
                }
              }
            }}
          >
              <div className="query-view" >
                {/* <div className="popular-filter-part">
                  <SubTitle title="Popular Filter for Untapped Innovation" />
                  <div className="popular-filter-part-content">
                    <Select
                      value={popularFilterValue}
                      style={{ width: "75%", height: 25 }}
                      onChange={handlePopularFilterChange}
                      options={[
                        {
                          value: 'Top 10 Females',
                          label: 'Top 10 Females',
                        },
                        {
                          value: 'test',
                          label: 'test',
                        }, 
                        {
                          value: 'test',
                          label: 'test',
                        }
                      ]}
                    />
                  </div>
                </div> */}
                <div className="search-paper-part">
                  <div className="search-paper-text">
                    <div className="search-paper-bullet">⋅</div>
                    <div className="search-paper-name">Search Paper</div>
                  </div>
                  <div className="search-paper-field" style={{width: "100%"}}>{searchPaperKeywordElement}</div>
                </div>

                <div className="split">
                  <svg className="split-line" xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 299 2" fill="none">
                    <path d="M0 1H299" stroke="#B6ACD1"/>
                  </svg>
                </div>

                <div className="paper-filter-part">
                  <div className="paper-filter-text">
                    <div className="paper-filter-bullet">⋅</div>
                    <div className="paper-filter-name">Paper Filter</div>
                  </div>
                  <div className="paper-filter-part-content">
                    <Form
                      size="middle"
                      labelAlign="right"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      layout="horizontal"
                      style={{ maxWidth: "100%" }}
                    >
                      <Form.Item label="Year">
                        <Slider
                            range
                            defaultValue={publicationYear}
                            min = {2000}
                            max = {2023}
                            marks={yearMarkLabels}
                            style={{ marginLeft: 8, marginTop: 0, width: "82%" }}
                            onChangeComplete={onPublicationYearChangeComplete}
                        />
                      </Form.Item>
                      {/* <Form.Item label="Month">
                        <Slider
                            range
                            defaultValue={publicationMonth}
                            min = {1}
                            max = {12}
                            tooltip={{
                              formatter,
                              placement: "top",
                            }}
                            marks={monthMarkLabels}
                            style={{ marginLeft: 8, marginTop: 0, width: "82%" }}
                            onChangeComplete={onPublicationMonthChangeComplete}
                        />
                      </Form.Item> */}
                      
                      {/*<Form.Item label="Keyword">*/}
                        {/*<Input placeholder="Search Title" onChange={(event) => handleInputChange(event.target.value)} style={{ width: "90%" }}/>*/}
                        {/* <Search placeholder="input search keyword" allowClear onSearch={handleSearchKeywordChange} style={{ width: "80%" }} /> */}
                     {/* </Form.Item> */}
                    </Form>
                  </div>
                </div>
                {/* <div className="confirm-button-container">
                  <div className="confirm-button">
                    <Button size={"small"}  icon={<SearchOutlined />} onClick={handleConfirmButton}>Confirm</Button>
                  </div>
                </div> */}
                {/* <div className="graphic-filter-part">
                  <SubTitle title="Graphic Filter" />
                  <div className="graphic-filter-part-content">
                    <Scatterplot viewName="researcher-scatterplot"/>
                  </div>
                </div> */}
              </div>
      </ConfigProvider>
    </div>
  )
}

export default QueryView